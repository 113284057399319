import React from 'react'
import { useState } from "react";
import { Link } from 'gatsby'
import { Sling as Hamburger } from 'hamburger-react'
import pfxlogo from "../images/pfxlogo.svg"
import arrow from "../images/arrow-white.svg"
import '../styles/global.css'

export default function Navbar( {style} ) {
    const [isOpen, setOpen] = useState(false)
    return (
        <nav style={style}>
            <figure>
                <Link className="logo" to="/">
                    <img src={pfxlogo} alt="planetfx"/>
                </Link>
            </figure>
            <article className="links">
                <Link to="/">Home</Link>
                <div className="navbar-down">
                    <div className="dropdown">
                        <p className="dropbtn">Produk 
                            <img src={arrow} alt="choose"/>
                        </p>
                        <div className="dropdown-content">
                            <Link to="/animation#content">Motion Graphic Animation</Link>
                            <Link to="/videography#content">Videography</Link>
                            <Link to="/graphic#content">Design Graphic</Link>
                        </div>
                    </div> 
                </div>
                <Link to="/tentang">Tentang Kami</Link>
                <Link to="/kontak">Kontak</Link>
            </article>
            <article className="links-mobile">
                <div className="navbar-down">
                    <div className="dropdown-mobile">
                        <div className="dropbtn-mobile"> 
                            <Hamburger
                            toggled={isOpen} toggle={setOpen} color="#ffffff" size={24} easing="ease-in"/>
                        </div>
                        <div className={isOpen ? "dropdown-content-mobile show" : "dropdown-content-mobile"}>
                            <Link to="/">Home</Link>
                            <Link to="/animation#content">Motion Graphic Animation</Link>
                            <Link to="/videography#content">Videography</Link>
                            <Link to="/graphic#content">Design Graphic</Link>
                            <Link to="/tentang">Tentang Kami</Link>
                            <Link to="/kontak">Kontak</Link>
                        </div>
                    </div> 
                </div>
            </article>
        </nav>
    )
}