import React from 'react'
import whatsapp from "../images/whatsapp-white.svg"
import instagram from "../images/instagram-white.svg"
import youtube from "../images/youtube-white.svg"
import email from "../images/email-white.svg"
import '../styles/global.css'

export default function Footer() {
    return (
        <footer>
            <article>
                <h2 className="footer-text">Tertarik bekerjasama dengan kami?</h2>
                <a href="https://wa.me/6281770891682" target="_blank" rel="noreferrer">
                    <button className="btn-orange">LET'S TALK</button>
                </a>
                <aside>
                    <a href="https://wa.me/6281770891682" target="_blank" rel="noreferrer">
                        <img src={whatsapp} alt="whatsapp"/>
                    </a>
                    <a href="https://www.instagram.com/planetfx/" target="_blank" rel="noreferrer">
                        <img src={instagram} alt="instagram"/>
                    </a>
                    <a href="https://www.youtube.com/user/radius877/featured" target="_blank" rel="noreferrer">
                        <img src={youtube} alt="youtube"/>
                    </a>
                    <a href="mailto:radius.antero96@gmail.com">
                        <img src={email} alt="email"/>
                    </a>
                </aside>
            </article>
        </footer>
    )
}
